.header-container {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;
}
.header {
    align-items: baseline;
    background-color: $white;
    display: flex;
    padding: 10px;
    justify-content: space-between;
    .link {
        color: $charcoal;
        font-size: 1rem;
        opacity: 0.75;
        padding: 0 22px;
    }
    .logo {
        margin-right: 10px;
        height: 1.6rem;
        width: 1.6rem;
    }
    .title {
        font-size: 1.75rem;
        //font-style: italic;
        font-weight: 600;
        margin: 0;
    }

}
.homelink {
    align-items: baseline;
    color: $green;
    display: flex;
    text-decoration: none;
}

.links {
    flex-grow: 1;
    text-align: right;
    display: none;
}

.link {
    display: inline-block;
    text-decoration: none;
}

.hamburger {
    align-self: flex-end;
    margin-bottom: 1px;
    width: 30px;
    .bar {
        background-color: $orange;
        height: 3px;
        margin: 5px 0;
        width: 100%;
    }
}

.no-header {
    display: none;
}

.mobile-header {
    background-color: $white;
    height: 0;
    border-bottom: 1px solid #EEEEEE;
    .link {
        color: $charcoal;
        display: flex;
        flex-direction: column;
        justify-content:  center;
        opacity: 0;
        padding: 15px 0;
        text-align: center;
        text-decoration: none;
        -webkit-animation: fadeIn .1s ease-in-out .2s forwards;
                animation: fadeIn .1s ease-in-out .2s forwards;
    }
    -webkit-animation: slidedown .2s ease-in-out forwards;
            animation: slidedown .2s ease-in-out forwards;
}

@keyframes slidedown {
    0% {
        height: 0;
    }
    100% {
        height: 210px;
    }
}
@-webkit-keyframes slidedown {
    0% {
        height: 0;
    }
    100% {
        height: 210px;
    }
}


@media (hover: hover) {
    .link:hover::after {
      width: 100%;
    }
    .link::after {
        background: $orange;
        content: '';
        display: block;
        height: 3px;
        width: 0;
        -webkit-transition: width .2s ease-in-out;
           -moz-transition: width .2s ease-in-out;
                transition: width .2s ease-in-out;
    }
}

.link:hover::after {
  width: 100%;
}
.link::after {
    background: $orange;
    content: '';
    display: block;
    height: 3px;
    width: 0;
    -webkit-transition: width .2s ease-in-out;
       -moz-transition: width .2s ease-in-out;
            transition: width .2s ease-in-out;
}

@media (min-width: $md-viewport) {
    .links {
        display: block;
    }
    .mobile-header {
      display: none;
    }
    .hamburger {
      display: none;
    }
}

.header-line {
    margin: 0;
    background-color: $green;
    background-image: linear-gradient(90deg, $light-green, $dark-green);
    height: 4px;
}
