.text {
    margin: 20px 0;
}
.sub-title {
    margin: 20px 0;
}
.about-header {
    margin: 0;
}
.about-text {
    margin: 5px 0;
}

.about-video-container {
  border-radius: 10px;
  -webkit-box-shadow: $box-shaodw;
     -moz-box-shadow: $box-shaodw;
          box-shadow: $box-shaodw;
}

.about-intro {
    background-color: $white;
    border-radius: 0 0 10px 10px;
    padding: 20px;
    margin: -4px 0 40px 0;
}

@media (min-width: 1000px) {
    .about-container {
        width: 70%;
    }
}
@media (min-width: $xxl-viewport) {
    .about-container {
        width: 60%;
    }
}

.employees-container {
    padding-top: 20px;
}

.row-bg {
  background-color: $white;
  border-bottom: 3px solid $green;
  -webkit-box-shadow: $box-shaodw;
     -moz-box-shadow: $box-shaodw;
          box-shadow: $box-shaodw;
}
.row-bg:first-of-type {
    border-radius: 10px 10px 0 0;
}
.row-bg:last-of-type {
    border-bottom: none;
    border-radius: 0 0 10px 10px;
}

.row-container {

    &.about-pictures {
      display: block;
      @media (min-width: $md-viewport) {
        display: flex;
      }
      .headshot {
        object-fit: cover;
        margin-bottom: 20px;
        width: 100%;
        @media (min-width: $md-viewport) {
          margin: 0 20px 0 0;
          flex: 0 0 250px;
        }
      }
    }

    padding: 20px;
    .name {
        margin: 0;
        padding: 0;
    }
    .text {
        margin: 0;
        padding: 10px 0;
    }
    .text:first-of-type {
        padding: 0;
    }
    .link-container {
      align-items: center;
      display: flex;
      margin: 5px 0;
      .icon {
          margin-right: 10px;
          height: 20px;
          width: 20px;
          flex: 0 0 20px;
      }
      .url {
        color: $green;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
}
