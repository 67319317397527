.imageGallery {
    margin: 20px 0 20px 20px;
    width: 200%;
    .image-container {
        max-height:100%;
        min-width: 100%;
    }
    .img {
        min-height: 400px;
        object-fit: cover;
        width: 100%;
    }
    .selector {
        align-items: center;
        display: flex;
        padding-top: 15px;
    }
    .circle {
        border: 2px solid $mid-grey2;
        border-radius: 100%;
        height: 15px;
        margin-right: 7px;
        width: 15px;

        &.fill {
            background-color: $mid-grey2;
        }
    }
}

@media (max-width: $lg-viewport) {
  .imageGallery {
      flex-basis: 100%;
      margin: 20px;
      .img {
          min-height: 250px;
      }
  }
}
