.sitemap-links {
    display: flex;
    flex-direction: column;
    .sitemap-header {
        margin: 10px 0;
    }
    .sitemap-link {
        color: $charcoal;
        margin: 4px 0;
        text-decoration: none;
    }
    .sitemap-link:hover {
        text-decoration: underline;
    }
}
