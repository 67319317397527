.article-container {
    background-color: $white;
    border-radius: 10px;
    padding: 1px 20px 20px 20px;
    position: relative;
    -webkit-box-shadow: $box-shaodw;
       -moz-box-shadow: $box-shaodw;
            box-shadow: $box-shaodw;
    .image-container {
        img {
            width: 100%;
        }
        .caption-container {
          display: flex;
          .bar {
              background-color: $green;
              margin: 0 7px 0 0;
              min-height: 100%;
              width: 4px;
          }
          p {
              margin: 5px 0;
          }
        }
    }
}

@media (min-width: $md-viewport) {
    .article-container {
        border-radius: 10px 0px 10px 10px;
        margin-right: 55px;
    }
}
