.logo-container {
    display: flex;
    justify-content: center;
    margin: 40px 0 20px 0;
    padding: 0;
    .logo {
        background-color: $mid-grey2;
        border-radius: 100%;
        margin: 0 20px;
        height: 125px;
        width: 125px;
    }
}
