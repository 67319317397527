.sendEmail {
    margin: 0 auto;
    width: 100%;
    .form-label {
        font-weight: 700;
    }
    .input_field {
        background-color: $white;
        border: 1px solid #DDDDDD;
        border-radius: 5px;
        box-sizing: border-box;
        font-weight: 400;
        font-size: 20px;
        margin: 5px 0 20px 0;
        outline: none;
        padding: 10px 15px;
        position: relative;
        resize: vertical;
        width: 100%;
    }
    .submit-button {
        color: $white;
        cursor: pointer;
        background-color: $orange;
        border: none;
        border-radius: 10px;
        font-size: 20px;
        padding: 20px 40px;
        width: 100%;
    }
}
