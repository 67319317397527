.footer-container {
    background-color: $mid-grey1;
    display: flex;
    padding: 40px 40px 140px 40px;
    position: relative;
    .logo {
        margin-right: 10px;
        height: 1.6rem;
        width: 1.6rem;
    }
    .title {
        font-size: 1.75rem;
        font-weight: 600;
        margin: 0;
    }
    .text {
        margin: 0;
    }
    .sub-container {
        display: block;
    }
    .footer-links {
        align-items: flex-start;
        display: inline-block;
        margin: 0;
    }
    .links-list {
        display: flex;
        flex-direction: column;
        margin: 20px 0;
    }
    .footer-link {
        color: $charcoal;
        display: inline-block;
        margin: 4px 0;
        text-decoration: none;
    }
    @media (hover: hover) {
      .footer-link:hover {
          text-decoration: underline;
      }
    }
    .footer-bottom {
        background-color: $mid-grey1;
        bottom: 0;
        left: 0;
        padding: 0 40px 15px 40px;
        position: absolute;
        right: 0;
        .bar {
            padding: 0 40px;
            border-top: 2px solid $mid-grey3;
        }
        .copyright {
            font-size: 11px;
            text-align: left;
        }
    }
}

@media (min-width: $s-viewport) {
    .footer-container {
      .footer-links {
          display: flex;
          margin: 20px 0 0 0;
      }
      .links-list {
          display: flex;
          margin: 4px 7% 4px 0;
      }
      .links-list:first-of-type {
          margin: 4px 7% 4px 0;
      }
    }
}

@media (min-width: 475px) {
    .footer-container {
      .links-list {
          display: flex;
          margin: 4px 10% 4px 0;
      }
      .links-list:first-of-type {
          margin: 4px 10% 4px 0;
      }
    }
}

@media (min-width: 600px) {
    .footer-container {
      .links-list {
          display: flex;
          margin: 4px 20% 4px 0;
      }
      .links-list:first-of-type {
          margin: 4px 20% 4px 0;
      }
    }
}

@media (min-width: 768px) {
    .footer-container {
      .links-list {
          display: flex;
          margin: 4px 30% 4px 0;
      }
      .links-list:first-of-type {
          margin: 4px 30% 4px 0;
      }
    }
}

@media (min-width: 1000px) {
    .footer-container {
      .sub-container {
          display: flex;
      }
      .footer-links {
          margin: 0;
      }
      .links-list {
          display: flex;
          margin: 0px 10%;
      }
      .links-list:first-of-type {
          margin: 0px 10% 0 80px;
      }
      .footer-link {
          font-size: 14px;
      }
    }
}

@media (min-width: 1224px) {
    .footer-container {
      .links-list {
          margin: 0px 15%;
      }
      .links-list:first-of-type {
          margin: 0px 15% 0 80px;
      }
    }
}

@media (min-width: 1442px) {
    .footer-container {
      .links-list {
          margin: 0px 20%;
      }
      .links-list:first-of-type {
          margin: 0px 20% 0 120px;
      }
    }
}
