.social-container {
    display: flex;
    flex-flow: row;
    justify-content: center;
    right: 0;
    top: 0;
    .logo-container {
        margin: 0 5px;
    }
    .social-logo {
        border-radius: 100%;
        height: 35px;
        width: 35px;
    }
}

@media (min-width: $s-viewport) {
    .social-container {
        justify-content: flex-end;
    }
}

@media (min-width: $md-viewport) {
    .social-container {
        flex-flow: column;
        margin-right: -55px;
        position: absolute;
        right: 0;
        top: 0;
        .logo-container {
            margin: 0;
        }
        .social-logo {
            border-radius: 0;
            height: 55px;
            width: 55px;
        }
        .logo-container:first-of-type > .social-logo {
            border-radius: 0 10px 0 0;
        }
        .logo-container:last-of-type > .social-logo {
            border-radius: 0 0 10px 0;
        }
    }
    .social-container:hover {
        cursor: pointer;
    }
}
