// responsive breakpoints
$s-viewport: 425px;
$sm-viewport: 576px;
$md-viewport: 768px;
$lg-viewport: 992px;
$xl-viewport: 1200px;
$xxl-viewport: 1600px;
$xxxl-viewport: 2000px;

// colors
$light-green: #0ca92a;
$dark-green: #006A37;
$green: #026E36;
$orange: #E77400;
$white: #FFFFFF;
$charcoal: #222222;
$light-grey: #EEEEEE;
$mid-grey1: #e1e1e1;
$mid-grey2: #DDDDDD;
$mid-grey3: #C6C3C3;

//shadows
$box-shaodw: 0px 0px 15px 0px rgba(0,0,0,0.05);
