.materials-container {
    align-content: center;
    cursor: pointer;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: auto;
    opacity: 1;
    // padding: 10px 0 20px 0;
    width:100%;
    .material-container {
        border-radius: 100%;
        margin: 25px;
        position: relative;
        .image-container {
          height: 250px;
          width: 250px;
        }
        .image {
            border-radius: 100%;
            width: 250px;
        }
        .overlay {
            background-color: #3336;
            border-radius: 100%;
            display: inline-block;
            height: 250px;
            left: 0;
            position: absolute;
            opacity: 1;
            top: 0;
            transition: .2s ease;
            width: 250px;
            //-webkit-animation: fadeIn 0.4s ease-in forwards;
            //        animation: fadeIn 0.4s ease-in forwards;
        }
        @media (hover: hover) {
            .overlay:hover {
                background-color: rgba(2, 110, 55, 0.4);
            }
            .overlay:hover .title::after{
                width: 100%;
            }
        }
        .title {
            color: $white;
            font-weight: 400;
            left: 50%;
            margin: 0;
            position: absolute;
            text-align: center;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
        }
    }
    .material-container:focus .overlay {
        background-color: rgba(2, 110, 55, 0.4);
    }
    .material-container:focus .title::after{
        width: 100%;
    }

    .active .overlay {
        background-color: rgba(2, 110, 55, 0.4);
    }
    .active .title::after{
        width: 100%;
    }


}

@media (min-width: $lg-viewport) {
    .materials-container {
        padding: 10px 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 100;
    }
}
@-webkit-keyframes fadeIn {
    0% {
        opacity: 0
    }
    100% {
        opacity: 100;
    }

}
