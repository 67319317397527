.nopagefound-page {
    background-color: $light-grey;
    .bg404 {
        left: 0;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        z-index: -3;
    }
    .gradient {
        background-color: #078832;
        background-image: linear-gradient(90deg, #3C7C60, #53A55E);
        bottom: 0px;
        left: 0;
        min-height: 100%;
        min-width: 100%;
        opacity: 0.6;
        position: fixed;
        top: 0;
        z-index: -2;
    }
    .nopagefound-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .link404 {
        text-decoration: none;
    }
    .header404 {
        color: $white;
        font-size: 150px;
        margin: 20px;
        text-align: center;
    }
    .sub-header404 {
        color: $white;
        font-size: 25px;
        margin: 20px 0 40px 0;
        text-align: center;
    }
    .return404 {
        background-color: transparent;
        border: 3px solid $white;
        border-radius: 10px;
        font-size: 18px;
        margin: auto;
        padding: 10px;
        text-align: center;
        transition: background-color .2s ease-in-out;
        width: 100%;
        text-decoration: none;
        p {
            color: $white;
        }
    }
    @media (hover: hover) {
        .return404:hover {
            background-color: $white;
            cursor: pointer;
            .return404:hover, p {
                color: $green;
            }
        }
    }

}
