.homepage-container {
    .title-container {
          position: absolute;
          left: 50%;
          top: 44%;
          transform: translate(-50%, -44%);
      .title {
          color: $white;
          font-size: 3em;
          font-weight: 600;
          //font-style: italic;
          margin: 0;
          text-align: center;
          @media (min-width: $md-viewport) {
            font-size: 4em;
          }
      }
      .title::after {
          background: $orange;
          content: '';
          display: block;
          height: 3px;
          margin: 0 0 4px 0;
          width: 0;
          -webkit-animation: slide-in 0.3s ease-in 0.3s forwards;
                  animation: slide-in 0.3s ease-in 0.3s forwards;
      }
      .sub_title {
          color: $white;
          font-size: 1.25rem;
          font-weight: 400;
          margin: 0;
          text-align: center;

          @media (min-width: $md-viewport) {
            font-size: 1.5rem;
          }
      }

      .button-container {
          position: relative;
      }
      .action-button {
          border: 2px solid $white;
          border-radius: 10px;
          color: $white;
          font-size: 1rem;
          left: 50%;
          margin-top: 15px;
          padding: 15px 30px;
          position: absolute;
          text-decoration: none;
          transform: translateX(-50%);
          transition: background-color .3s ease-in-out;
          white-space: nowrap;
      }
      @media (hover: hover) {
          .action-button:hover {
              background-color: $white;
              color: $dark-green;
              transition: background-color .3s ease-in-out;
          }
      }
  }
    .bg-video {
        left: 0;
        height: 100%;
        min-width: 100%;
        object-fit: inherit;
        position: fixed;
        top: 0;
        z-index: -3;
    }
    .gradient {
        background-color: #078832;
        background-image: linear-gradient(90deg, #3C7C60, #53A55E);
        bottom: 0;
        left: 0;
        min-height: 100%;
        min-width: 100%;
        opacity: 0.6;
        position: fixed;
        top: 0;
        z-index: -2;
    }
    @media (min-width: $md-viewport) {
        .gradient {
            opacity: 0.5;
        }
    }
}

@-webkit-keyframes slide-in {
  from {width: 0;}
  to {width: 100%;}
}
@keyframes slide-in {
  from {width: 0;}
  to {width: 100%;}
}
