.banner-container {
    background-color: $white;
    border-radius: 10px;
    margin: 20px 0;
    -webkit-box-shadow: $box-shaodw;
       -moz-box-shadow: $box-shaodw;
            box-shadow: $box-shaodw;
    // .horizontal-line {
    //     background-color: $green;
    //     height: 3px;
    //     margin-bottom: 7px;
    //     width: 100%;
    // }
    .image-container {
        padding: 20px;
    }
    .image {
        height: 100%;
        object-fit: contain;
        max-width: 100%;
        width: 100%;
    }
    .text-container {
        max-width: 100%;
        padding: 0 20px 20px 20px;
        .title2 {
            margin: 0px;
        }
        .text2 {
            margin: 5px 0;
        }
    }
}
.horizontal-line {
    background-color: $green;
    height: 3px;
    margin-bottom: 7px;
    width: 100%;
}
@media (min-width: 1000px) {
    .banner-container {
        display: flex;
        flex-flow: nowrap;
        .text-container {
            max-width: 49%;
            padding: 20px 20px 20px 0;
        }
        .image-container {
            flex-basis: 50%;
        }
    }
}
