html {
  font-size: 16px;

  @media (min-width: $md-viewport) {
    font-size: 18px;
  }
}

body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
div {
    box-sizing: border-box;
}

p {
    line-height: 1.5;
}

.page-bg {
    background-color: $light-grey;
    min-height: calc(100vh - 334px);
}

.page-container {
    display:block;
    width: 85%;
    margin: auto;
    padding-bottom: 30px;
    overflow-y: auto;
    overflow: visible;
}

.main-title {
    display: inline-block;
    margin: 0;
    padding: 20px 0 0 0;
}
.main-title::after {
    background: $orange;
    content: '';
    display: block;
    height: 3px;
    width: 0;
    -webkit-animation: slide-in 0.2s ease-in 0.2s forwards;
            animation: slide-in 0.2s ease-in 0.2s forwards;
}

@media (min-width: $s-viewport) {
    .page-container {
        padding-bottom: 40px;
        width: 80%;
    }
}

@media (min-width: 1000px) {
    .page-container {
        width: 70%;
    }
}

@media (min-width: $xxl-viewport) {
    .page-container {
        width: 60%;
    }
}

.noselect {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.nodrag {
  -webkit-user-drag: none;
   -khtml-user-drag: none;
     -moz-user-drag: none;
       -o-user-drag: none;
}
