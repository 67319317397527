.productList-container {
    display: block;
    margin: auto;
    min-height: calc(100vh -281px);
    width: 100%;
    overflow-y: auto;
    padding-bottom: 20px;
    .main-title {
        margin-left: 10vw;
    }
    .cards-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 20px 0;
    }
    .card-link {
        color: $charcoal;
        text-decoration: none;
        margin: 20px;
    }
    .card-container {
        background-color: $white;
        border-radius: 10px;
        display: flex;
        flex-flow: row wrap;
        width: 250px;
        -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.07);
           -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.07);
                box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.07);

    }
    .poduct-image-container {
        height: 250px;
        margin: 10px;
        position: relative;
        width: 250px;
    }
    .poduct-image {
        position: absolute;
        left: 50%;
        max-width: 100%;
        top: 50%;
        height: 70%;
        width: auto;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
    }
    .sub-container {
        border-radius: 0 0 10px 10px;
        padding: 10px;
        background-color: $white;
        transition: background-color 0.25s;
        width: 100%;
        .green-line {
            background-color: $green;
            height: 3px;
            width: 100%;
        }
        .title {
            margin: 4px 0;
        }
        p {
            font-size: 12px;
            margin: 0;
        }
    }
    @media (hover: hover) {
        .card-container:hover .sub-container {
            background-color: $green;
        }
        .card-container:hover .green-line {
            background-color: $white;
        }
        .card-container:hover .title {
            color: $white;
        }
        .card-container:hover p {
            color: $white;
        }
    }

}

@media (min-width: $lg-viewport) {
    .productList-container {
        width: 70%;
        .main-title {
            margin-left: 0;
        }
        .cards-container {
            margin: 20px 0 40px 0;
        }
    }
}
@media (min-width: $xxl-viewport) {
    .productList-container {
        width: 60%;
    }
}
