.product-details-container {
    background-color: $white;
    border-radius: 10px;
    margin: 20px 0;
    -webkit-box-shadow: $box-shaodw;
       -moz-box-shadow: $box-shaodw;
            box-shadow: $box-shaodw;
    .header-video-container {
        height: 100%;
        width: 100%;
        .video {
            border-radius: 10px 10px 0 0;
            width: 100%;
            height: 100%;
        }
    }
    .features {
        margin: 14px 0;
    }
    .details-container {
        display: flex;
        flex-wrap: nowrap;
        .details {
          margin: 0 20px 20px 20px;
        }
        @media (max-width: 1000px) {
          .details {
              flex-basis: 100%;
          }
        }
    }

}
@media (max-width: $lg-viewport) {
    .product-details-container {
        margin: 20px auto 40px auto;
        width:80%;
        .details-container {
            flex-wrap: wrap;
        }
        .features {
            margin: 0;
        }
    }

}

// .sub-card-container {
//     overflow-x: hidden;
//     overflow-y: hidden;
//     white-space: nowrap;
//     -webkit-overflow-scrolling:touch;
//     &::-webkit-scrollbar {
//         display: none;
//         background: transparent;
//     }
// }

// .sub-card {
//     margin: 20px 40px 20px 0;
//     background-color: $white;
//     border-radius: 10px;
//     display: inline-block;
//     width: 250px;
//     -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.05);
//        -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.05);
//             box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.05);
//      -webkit-animation: bannermove 15s linear infinite;
//              animation: bannermove 15s linear infinite;
//     .image-container {
//         height: 250px;
//         position: relative;
//         width: 250px;
//     }
// }
//
// @keyframes bannermove {
//     0% {
//         transform: translate3d(0, 0, 0);
//     }
//     100% {
//         transform: translate3d(-1160px, 0, 0);
//     }
// }
// @-webkit-keyframes bannermove {
//     0% {
//         transform: translate3d(0, 0, 0);
//     }
//     100% {
//         transform: translate3d(-1160px, 0, 0);
//     }
//
// }

.extra-info-container {
    background-color: $white;
    border-radius: 10px;
    margin: 20px auto;
    padding: 20px;
    width: 80%;
    -webkit-box-shadow: $box-shaodw;
       -moz-box-shadow: $box-shaodw;
            box-shadow: $box-shaodw;
    .image-container {
       display: block;
    }
    .img-subcontainer {
        flex-basis: 50%;
        max-height:100%;
        margin-bottom: 20px;
        min-width: 50%;
    }
    .img {
        height: 100%;
        width: 100%;
    }
    .info-header {
        margin: 0;
    }

}

.product-paragraph {
    margin: 5px 0;
}

.banner-link {
  text-decoration: none;
}

// .next-banner-container {
//     background-color: $white;
//     border: 4px solid $green;
//     border-radius: 10px;
//     display: block;
//     margin: 0 auto;
//     margin-bottom: 40px;
//     width: 80%;
//     .vertical-line {
//         background-color: $green;
//         display: none;
//         width: 15px;
//     }
//     .image {
//         display: none;
//         padding: 20px;
//         height: 150px;
//     }
//     .text-container {
//         padding: 20px;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         height: 125px;
//         width: 100%;
//     }
//     .header {
//         color: $charcoal;
//         margin: 0;
//         padding: 0;
//     }
//     .text {
//         color: $charcoal;
//         margin: 5px 0;
//     }
//     .nextButton {
//         align-self: center;
//         color: $charcoal;
//         margin: 0;
//         padding: 0px 20px 20px 20px;
//         text-decoration: underline;
//         white-space: nowrap;
//     }
// }


@media (max-width: $lg-viewport) {
    .sub-card-container {
        margin: 0 auto;
        width: 100%;
    }
    .sub-card {
        margin: 0 40px 20px 0;
    }
    .first {
        //margin-left: 40px;
    }
}
@media (min-width: $lg-viewport) {
    .extra-info-container {
          width: 100%;
          .image-container {
              display: flex;
          }
          .img-subcontainer {
              margin: 0 20px 0 0;
          }
          .info-header {
             margin: 0;
             padding: 0;
          }
    }
    .next-banner-container{
      border: none;
      display: flex;
      flex-flow: nowrap;
      width: 100%;
      .vertical-line {
          display: block;
      }
      .image {
          display: block;
          padding: 40px 60px;
      }
      .nextButton {
          padding: 0 60px 0 20px;
      }
      .text-container {
          padding: 30px 20px 30px 0;
          height: 140px;
      }
    }
}

// @media (hover: hover) {
//     .sub-card-container:hover .sub-card{
//         animation-play-state: paused;
//     }
// }
