.news-headers {
  display: block;
  margin: 20px 0 10px 0;
  .latest-header-continers {
      align-items: center;
      display: block;
      justify-content: space-between;
  }
  .news-header {
      margin: 0;
  }
  .news-date {
      color: $charcoal;
      text-align: left;
  }
  .latest-container {
      color: $charcoal;
      cursor: pointer;
      text-decoration: none;
      width: 100%;
  }
  .latest-article {
      background-color: $white;
      border-radius: 10px;
      padding: 20px;
      -webkit-box-shadow: $box-shaodw;
         -moz-box-shadow: $box-shaodw;
              box-shadow: $box-shaodw;
  }
  .statements {
      background-color: $white;
      border-radius: 10px;
      display: block;
      flex-basis: 100%;
      margin: 40px 0 20px 0;
      padding: 20px;
      width: 100%;
      -webkit-box-shadow: $box-shaodw;
         -moz-box-shadow: $box-shaodw;
              box-shadow: $box-shaodw;
  }
  .latest-image {
      width: 100%;
  }
}

.more-link {
  margin: 0;
  text-decoration: underline;
  color: blue;
  display: inline-block;
}

.article-link-row {
    color: $charcoal;
    cursor: pointer;
    text-decoration: none;
}

.article-preview-image {
    margin: 0 0 20px 0;
    height: auto;
    object-fit: cover;
    width: 100%;
}

.row-date {
    color: $charcoal;
    margin: 0;
}

.news-link-container {
    max-height: 250px;
    overflow-y: auto;
}

.news-link {
    display: flex;
    margin: 15px 0;
    .vertical-line {
        background-color: $green;
        margin: 6px 7px 3px 0;
        padding: 2px;
        width: 2px;
    }
    .urlHeader {
        margin: 0;
    }

    .url {
        color: blue;
        margin: 0;
        text-decoration: underline;
    }
}

.news-link:first-of-type {
    margin: 0;
}

@media (min-width: $md-viewport) {
    .news-headers {
        .latest-header-continers {
            display: flex;
        }
        .news-date {
            text-align: right;
        }
    }
    .news-container {
        display: flex;
    }
    .article-preview-image {
        margin: 0 20px 0 0;
        height: 250px;
        width: 250px;
        flex: 0 0 250px;
    }
}

@media (min-width: 1100px) {
    .news-headers {
        display: flex;
        .latest-article {
            height: 100%;
        }
        .latest-container {
            flex-basis: 70%;
            flex-grow: 4;
        }
        .statements {
            flex-basis: 30%;
            flex-shrink: 1;
            margin: 0 0 0 20px;
            overflow: auto;
        }
    }
    .news-link-container {
        max-height: 33vw;
    }
}

@media (min-width: 1224px) {
    .news-link-container {
        max-height: 31vw;
    }
}

@media (min-width: 1350px) {
    .news-headers {
        display: flex;
        .latest-container {
            flex-basis: 70%;
        }
        .statements {
            flex-basis: 30%;
        }
    }
    .news-link-container {
        max-height: 30.5vw;
    }
}
@media (min-width: 1600px) {
    .news-link-container {
        max-height: 26vw;
    }
}
@media (min-width: 1745px) {
    .news-link-container {
        max-height: 25vw;
    }
}
@media (min-width: 2560px) {
    .news-link-container {
        max-height: 23vw;
    }
}
